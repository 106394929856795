.modal {
  height: 100% !important;
  width: 100% !important;
  max-width: none !important;
  max-height: none !important;
}

.flexContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messageBody {
  font-size: 1rem !important;
}
