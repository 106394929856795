.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  user-select: none;
  cursor: pointer;
  justify-content: flex-end;
}

.toggle > span {
  margin-right: 15px;
}

.chart {
  display: flex;
  justify-content: center;
  clear: right;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.button {
  margin-top: 10px;
  float: right;
  /* Taken from the chart size */
  width: calc(500px - 20px - 20px);
}

.loadingContainer {
  width: 100%;
  height: auto;
  min-height: 150px;
  margin-bottom: 20px;
  overflow-y: auto;
}
