.box {
  padding: var(--standardMargin);
}

.boxWrapper {
  display: contents;
}

.withMargin {
}

@media print {
  .box {
    background: var(--surface7Color);
    margin-bottom: var(--standardMargin);
  }

  /* Enable the wrapper when printing. Used to insert a small block with padding
       before the box but right after the page break happens. Needed since we've removed
       all of the padding on the page to avoid printing URL:s etc  */
  .boxWrapper {
    display: block;
    break-inside: avoid;
  }

  /*
      Make the margin between the first box in a section and the section title smaller.
    */

  .boxWrapper:first-child > div:first-child {
    margin-top: calc(-1 * var(--hugeMargin));
  }

  .boxWrapper:before {
    content: '';
    display: block;
    width: 1px;
    height: calc(var(--hugeMargin) + var(--largeMargin));
  }
}
