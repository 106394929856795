.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.negative {
  color: var(--errorColor);
}

.modal {
  max-width: 980px !important;
}

.hasError {
  color: var(--errorColor);
}

.diagramGrid {
  display: grid;
  gap: var(--standardMargin);
  grid-template-columns: 1fr 1fr;
}

.diagramGrid > div:first-child {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.expander {
  flex-grow: 1;
}

.importTable {
  position: relative;
  margin-top: -113px;
}

.importArrow {
  position: absolute;
  right: -105px;
  top: 18px;
}

.upArrow {
  margin-top: -80px;
  margin-left: 53%;
  display: block;
}

@media print {
  .printBreak {
    page-break-before: always;
    break-before: page;
    margin-bottom: var(--hugeMargin);
  }

  .infoButton {
    display: none;
  }
}
