.body ul {
  list-style: circle;
  margin-left: var(--standardMargin);
}

.body label {
  font-weight: bold;
  margin-bottom: var(--largeMargin);
  display: block;
}
