.schedule {
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.arrow {
  padding: 0 5px;
}

.infoArea {
  flex: 1 1 auto;
  line-height: 1.5;
}

.progressArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.progressArea > div + label {
  margin-left: var(--standardMargin);
}

.progressArea > label + span:before {
  content: '\00a0';
}

.bottomInfo {
  margin-top: 5px;
  font-size: 0.7rem;
}

.location {
  color: var(--accent3Color);
  margin-right: 5px;
  padding-right: 5px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.toolArea {
  flex: 0 0 auto;
}

.icon {
  margin-left: 5px;
  cursor: pointer;
  display: inline-block;
  user-select: none;

  .edit,
  .abort,
  .arrowLink,
  .delete {
    stroke: rgba(0, 0, 0, 0.4);
  }

  &:hover {
    .edit {
      stroke: rgba(0, 0, 0, 0.55);
    }

    .arrowLink,
    .abort,
    .delete {
      stroke: var(--errorColor);
    }
  }
}

.dates,
.status {
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 5px;
  padding-left: 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.stopschedule {
  color: var(--errorColor);
}

.ABORTED {
  color: var(--errorColor);
}

.RUNNING {
  color: green;
}

.PENDING {
  color: var(--accent2Color);
}

.FINISHED {
  color: grey;
}

.dates {
  opacity: 0.8;
}
