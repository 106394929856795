.searchField {
  width: 180px;
}

.optionsMenu {
  min-width: 350px;
  max-width: 350px;
}

.optionsMenu > div {
  overflow-y: auto;
  max-height: 600px;
}

.optionsMenu > div {
  width: 100%;
}

.destructive {
  color: var(--errorColor) !important;

  svg {
    color: var(--errorColor) !important;
  }
}
