.schedule {
  font-size: 0.85rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.title {
  cursor: pointer;
  padding: 12px 12px;
  color: var(--textColor);
  user-select: none;

  &.showDetails {
    background: var(--accent2Color);
    color: white !important;

    * {
      color: white;
      stroke: white;
      border-color: rgba(255, 255, 255, 0.5);
    }
  }

  &:hover:not(.showDetails) {
    background: rgba(0, 0, 0, 0.03);
  }

  &:active:not(.showDetails) {
    background: rgba(0, 0, 0, 0.075);
  }
}

.details {
  background: rgba(0, 0, 0, 0.03);
  padding: 6px 8px;
  height: 120px;
}

.location {
  color: var(--accent3Color);
  font-size: 0.7rem;
}

.status {
  font-weight: bold;
  font-size: 0.75rem;
}
