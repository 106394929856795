.automaticSchedulesContainer {
  display: flex;
  align-items: center;
  height: 36px;

  label {
    font-size: 0.95em;
    margin-right: var(--standardMargin);
  }
}
