.gridLayout {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  column-gap: var(--standardMargin);
  row-gap: var(--standardMargin);
  grid-template-rows: 50px 1fr;
  grid-template-columns: minmax(400px, 5fr) 7fr;

  grid-template-areas:
    'toolbar toolbar'
    'details scoring';
}
