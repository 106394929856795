.container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.viewWithIcons img {
  max-width: 30px;
  max-height: 30px;
}

.modal {
  max-width: 90vw !important;
  height: 95vh !important;
  min-height: 95vh !important;
}

.modal {
  div + h3 {
    margin-top: var(--largeMargin);
  }
}

@media (min-width: 1600px) {
  .modal {
    max-width: 1500px !important;
  }
}

@media (min-width: 2000px) {
  .modal {
    max-width: 1700px !important;
  }
}

.expandingItem {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buildStatus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;
}

.buildStatus > div:first-child {
  margin-right: var(--standardMargin);
}

.grid {
  display: grid;
  box-sizing: border-box;
  flex-grow: 1;
  max-width: 100%;
  height: auto;
  grid-gap: var(--standardMargin);
  align-content: flex-start;
  justify-content: flex-start;
}

.grid > div {
}

.fullHeight {
  display: flex;
  height: 100%;
}

.projectHeader {
  font-weight: bold;
  font-size: 2.25em;
  padding-top: var(--largeMargin);
  margin-bottom: var(--largeMargin);
}

.paddedArea {
  padding: var(--standardMargin);
}

.resultViewContainer {
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.printViewContainer {
  background: white;
}

.resultBox,
.autoResultBox {
  padding: var(--standardMargin);
  display: flex;
  flex-direction: column;
  box-shadow: var(--panelShadow);
}

.resultBox {
  min-height: 330px;
}

.bottomPadding {
  padding-bottom: var(--largeMargin);
}

.header {
  padding-top: var(--hugeMargin);
  margin-bottom: var(--standardMargin);
  font-size: 1.5em;
  font-weight: 600;
}

.printContainer {
  padding: var(--largeMargin);
  background: white;
  overflow-y: auto;
  width: 800px !important;
}

.printContainer div {
  box-shadow: none !important;
}

@media print {
  .printContainer {
    padding: 0 calc(var(--hugeMargin) * 2);
    overflow: visible;
  }
  .header {
    margin-bottom: 0;
  }
}

.printSection {
  padding-top: var(--largeMargin);
  break-inside: avoid-page;
}

.printBody {
  overflow: auto;
  overflow-y: auto;
  -webkit-print-color-adjust: exact !important;
}

.infoBox {
  width: 100%;
}

.oldVersionModal {
  max-width: min(2000px, 85vw) !important;
  min-height: 95vh !important;
}

.oldVersionContentBox {
  margin-top: var(--standardMargin);
}

.progressBarContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buildPickerItem {
  min-width: 200px;
}

.fixedHeader {
  background: white;
  position: sticky;
  top: 0;
  border-bottom: 0;
  margin-bottom: 0;
  padding: var(--standardMargin);
  padding-bottom: 0;
  z-index: 400;
}

.container {
  position: relative;
}

.grid {
  padding: var(--standardMargin);
}

.toolbarShadow {
  box-shadow: var(--navBarShadow);
}

.resultPageBox {
  padding: 0px;
}

.networkOverview {
  padding: var(--standardMargin);
  max-width: 900px;
}

.newPage {
  page-break-before: always;
  break-before: page;
}
