.exportModal {
  width: 400px;
}

.exportRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.exportRow label {
  font-weight: 500;
}

.exportRow:first-child {
  margin-top: 0px;
}

.exportSelect {
  width: 150px;
  font-size: 0.9em;
}

.exportSelect__option {
  font-size: 0.9em;
  color: green;
}

.error {
  color: var(--errorColor);
}
