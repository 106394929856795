.graphContainer {
  flex-grow: 1;
  min-height: 150px;
  height: 100%;
}

.graphTitle {
  font-size: 1.3em;
  height: 35px;
  color: var(--textColor);
}

.innerGraphContainer {
  flex-grow: 1;
  display: flex;
}

.innerGraphBox {
  min-height: 400px;
}

.resultBox {
  min-height: 330px;
  display: flex;
  flex-direction: column;
  padding: var(--standardMargin);
}

.infoBox {
  padding: var(--standardMargin);
  display: flex;
  flex-direction: column;
  margin-top: var(--standardMargin);
}

.infoBox + div {
  margin-top: var(--standardMargin);
}

.flowInfo {
  font-style: italic;
}
