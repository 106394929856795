.seriesSelectControl {
  width: 240px;
}

.selectControlHeader {
  text-transform: none !important;
  font-size: 12px;
  opacity: 1;
  user-select: none !important;
  padding: var(--insetMargin) 0 var(--insetMargin) 0;
}

.seriesSelectControlValue {
  border: 1px solid black;
  width: 200px;
  flex-grow: 1;
}

:global .Select__option--is-focused {
  .selectControlHeader {
    background-color: var(--surface2Color) !important;
    color: green !important;
    border: 1px solid blue;
  }
}

@media (max-width: 2100px) {
  .seriesSelectControl {
    width: 180px;
  }
}

.label {
  margin-left: var(--insetMargin) !important;
  margin-right: var(--insetMargin) !important;
}
