.title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--textColor);
  margin-left: calc(-1 * var(--standardMargin));
  margin-right: calc(-1 * var(--standardMargin));
  padding-bottom: var(--standardMargin);
  height: 30px;
  padding-left: var(--standardMargin);
  box-sizing: border-box;
}

@media print {
  .title {
    font-weight: 600;
    border-bottom: 0;
  }
}

.title:first-child {
  margin-top: 0;
}
