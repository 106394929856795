.calculationIcon {
  margin-right: var(--smallMargin);
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.balancingSelector {
  margin-right: var(--largeMargin);
  margin-bottom: var(--largeMargin);
}

.section {
  width: 430px;
}

.buildingSection {
  box-sizing: border-box;
  width: 100%;
}

.constrainedSection {
  max-width: 606px;
}

.flexWrapLayout,
.buildingSection {
  display: flex;
  flex-wrap: wrap;
  gap: var(--standardMargin);
}

.buildingSectionItem {
  width: 1px;
  flex-grow: 1;
  max-width: 530px;
}

.errorModal {
  max-width: 700px !important;
}

.errorSection {
  margin-bottom: var(--standardMargin);
}

.editBuildingModal {
  max-width: 700px !important;
  min-height: min(90vh, 980px) !important;
}

.summaryModal {
  max-width: 1100px !important;
  min-height: min(90vh, 980px) !important;
}

.techTable img {
  max-width: 30px;
  max-height: 30px;
}

.assetGrid {
  margin-top: var(--largeMargin);
  margin-bottom: var(--largeMargin);
  display: grid;
  gap: var(--standardMargin);
  grid-template-rows: repeat(3, 120px);
  grid-template-columns: repeat(4, 1fr);
}

.assetGrid > div,
.assetGrid > div > button {
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--borderColor);
  border-radius: var(--tableBorderRadius);
}

.assetGrid > div > button {
  background-color: transparent !important;
  border: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.assetGrid > div:hover {
  background-color: var(--surface5Color);
}

.assetGrid img {
  max-width: 50px;
  max-height: 50px;
}

.assetAddIcon {
  position: absolute;
  top: var(--insetMargin);
  right: var(--standardMargin);
}

.assetGrid > div > button > .assetAddIcon {
  right: 0;
}
