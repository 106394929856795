.header {
  flex: 0 0 auto;
}

.content {
  flex: 1 1 auto;
  flex-shrink: 0;
}

.mainContent {
  min-height: 468px;
}

.stopContent {
  min-height: 395px;
}

.summaryContent {
  min-height: 120px;
}

.content + .content {
  margin-top: 15px;
}

.header,
.content {
  width: 100%;
}

.presetContainer {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: var(--largeMargin);
  margin-top: var(--largeMargin);
}

.test {
}

.flexboxContainer {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}

.flexboxContainer input[type='radio'] {
  margin-right: 7px;
}

.flexboxContainer input[type='range'] {
  flex: 1;
}

.flexboxContainer label {
  flex-grow: 1;
  user-select: none;
}

.amplitudeLabel {
  flex-grow: 0 !important;
  width: 50%;
}

.amplitudeInput {
  width: 50px;
  text-align: center;
}

.amplitude {
  width: 100%;
  appearance: none;
  &:focus {
    outline: 0;
  }
}
.amplitude::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    #0033ee 0,
    #fff 50%,
    var(--failureColor)
  );
  border-radius: 4px;
  border: 1px solid var(--borderColor);
}

.amplitude::-moz-range-track {
  height: 13px;
  background: linear-gradient(
    to right,
    #0033ee 0,
    #fff 50%,
    var(--failureColor)
  );
  border-radius: 4px;
  border: 1px solid var(--borderColor);
}

.amplitude::-ms-track {
  height: 13px;
  background: linear-gradient(
    to right,
    #0033ee 0%,
    #fff 50%,
    var(--failureColor)
  );
  border-radius: 4px;
  border: 1px solid var(--borderColor);
}

.reverseAmplitude::-webkit-slider-runnable-track {
  background: linear-gradient(
    to left,
    #0033ee 0%,
    #fff 50%,
    var(--failureColor)
  );
}

.reverseAmplitude::-moz-range-track {
  background: linear-gradient(
    to left,
    #0033ee 0%,
    #fff 50%,
    var(--failureColor)
  );
}

.reverseAmplitude::-ms-track {
  background: linear-gradient(
    to left,
    #0033ee 0%,
    #fff 50%,
    var(--failureColor)
  );
}

.amplitude::-ms-thumb {
  border: 1px solid #000000;
  height: 13px;
  width: 13px;
  border-radius: 7px;
  background: #ffffff;
  cursor: pointer;
}

.amplitude::-ms-fill-upper {
  display: none;
}

.amplitude::-ms-fill-lower {
  display: none;
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  & > * {
    flex: 1;
    width: 33%;
  }
}

.spinner {
  &:first-child {
    padding-right: 10px;
  }
}

.nowButton {
  padding: 5px;
  margin-right: 5px;
  max-height: 25px;
  margin-top: auto;
}

.messageBody {
}

.modalResizer {
  max-width: 600px;
  max-height: none;
  height: auto;
}

.largerModal {
  max-width: 870px;
  min-height: 600px;
}

.select,
.calendarInput {
  width: 50%;
}

.segmentControl {
  width: 100%;
  align-items: stretch;
}

.segmentItem {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.segmentItemRow {
  display: flex;
  font-size: 0.95em;
}

.segmentItemHeader {
  font-size: 1.25em;
  margin-bottom: 8px;
}

.largerModal .segmentItemHeader {
  font-size: 1.05em;
}

.segmentItemRow label {
  cursor: pointer;
  font-weight: 600;
  margin-right: 5px;
}

.content h5 {
  margin-top: 5px;
}

.content h5:first-child {
  margin-top: 0px;
}

@media (max-height: 650px) {
  .segmentItemHeader {
    display: none;
  }
}
@media (max-height: 570px) {
  .messageBody {
    overflow-y: auto;
  }

  .content h5 {
    display: none;
  }
}
