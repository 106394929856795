.box {
  padding-bottom: 0;
}
.optionSection {
  border-bottom: 1px solid var(--surface4Color);
}

.optionContainer {
  display: flex;
  max-width: 800px;
  justify-content: flex-start;
}

.signalsContainer {
  height: 1px;
  flex-grow: 1;
  overflow-y: auto;
  margin-left: -10px;
  font-size: 0.9em;
}
