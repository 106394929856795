.operatorChart {
  flex: 1 1 auto;
  height: 100%;
}

.smallSelector {
  font-size: 0.9em;
  width: 280px;
}

.flexibleSpace {
  flex-grow: 1;
}
.chart {
  border-radius: 3px;
  overflow: hidden;
  height: 100%;
}

.chart label {
  margin-left: 10px;
  font-size: 0.8em;
  color: var(--surface3Color);
}

.error {
  color: var(--errorColor);
}

@media (max-width: 1710px) {
  .toolbarButton svg {
    margin: 0px;
  }

  .collapsingSpace {
    display: none;
  }

  .collapsingHeader {
    width: 100%;
    margin-bottom: var(--standardMargin);
  }

  .toolbarButton {
    padding: 0;
    width: var(--iconButtonWidth);
    height: var(--iconButtonHeight);
  }

  .toolbarButtonText {
    display: none;
  }

  .smallSelector {
    width: 180px;
  }
}

.label {
  margin-left: var(--insetMargin) !important;
  margin-right: var(--insetMargin) !important;
}

.unsavedLabel {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.toolbar {
  border-bottom: 0;
  padding-top: 0;
}

.graphContainer {
}
