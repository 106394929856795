.details {
  width: 100% !important;
  box-sizing: border-box;
  border-top: var(--tableBorderStyle);
  flex-direction: column;
}

.details {
  flex-grow: 1;
  height: 1px;
  display: flex;
}

.settings {
  padding: var(--smallMargin) var(--standardMargin) 0 var(--standardMargin);
}

.content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.settings > div {
  flex-grow: 0;
}

.modalBody {
  padding: 0px;
}

.modalResizer {
  height: 95% !important;
  max-height: none !important;
  max-width: 1800px !important;
}

.segmentControl {
  margin-left: var(--standardMargin);
  margin-top: var(--standardMargin);
  margin-bottom: var(--standardMargin);
}

.signalSelector {
  height: 100%;
  line-height: normal;
}

.settingsContainer {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
