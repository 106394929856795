.box {
  margin-bottom: var(--standardMargin);
}

.box label {
  font-weight: 600;
}

.box button {
  margin-top: var(--standardMargin);
}
