.warning {
  color: var(--failureColor);
}

.modal {
  max-width: 650px;
  max-height: 800px;
}

.modalBody {
  padding: 0;
  overflow: hidden;
  background: white;
}

.modalContent {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buildingInfo {
  display: flex;
  min-height: 22px;
  white-space: pre-wrap;
}
