.container {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.paddedArea {
  padding: var(--standardMargin);
}

.graphContainer {
  height: 1px;
  flex-grow: 1;
}

.graphSelector {
  min-width: 300px;
}

.infoView {
}

.notice {
  margin-bottom: var(--standardMargin);
}
