:root {
  --buttonAreaHeight: 50px;
}

.container {
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--surface6Color);
}

.schedulesContainer {
  height: 360px;
  overflow-y: auto;
}

.contentArea {
  flex: 1 1 auto;
  padding-bottom: 10px;
  box-sizing: border-box;

  > *:last-child {
    border-bottom: 0;
  }
}

.buttonArea {
  flex: 0 0 auto;
  text-align: right;
  padding-top: 10px;
  margin-right: var(--standardMargin);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.manualContainer {
  padding: 10px;
  border-radius: 3px;
}

.operatorChart {
  flex-grow: 1;
}

.dispatchPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.toolbar,
.notice {
  padding: var(--standardMargin);
}
