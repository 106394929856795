.emptyNotice {
  padding-left: var(--standardMargin);
  padding-top: var(--smallMargin);
}

.infoBox {
  margin-top: var(--largeMargin);
}

.optimisationBox {
  flex-shrink: 0;
  margin-bottom: var(--standardMargin);
}
