.dateInputWrapper {
  height: 32px;
  border-radius: 5px;
}

.dateInputWrapper input {
  padding: 5px 5px;
}

.datePickerSection {
  display: flex;
  align-items: center;
}

.toolbarMenuItemLeftInput {
  border-radius: 5px 0 0 5px;
}

.toolbarMenuItemRightInput {
  border-radius: 0 5px 5px 0;
}

.toolbarMenuItemDateInputWrapper {
  flex-grow: 1;
  &:first-child,
  &:last-child {
    border: none;
    padding: 0;
  }
}

@media (max-width: 1200px) {
  .datePickerSection label {
    display: none;
  }

  .datePickerSection .dateInput:first-child {
    margin-right: 5px;
  }
}

.dateTimeContainer {
  display: flex;
  flex-direction: row;
}

.toolbarItemDatePicker {
  background: var(--inputBackground);
  border: 1px solid var(--borderColor);
  border-radius: var(--insetMargin);
}

.dateTextField {
  width: 140px !important;
}
