.pickerContainer {
  display: grid;
  background: white;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr 1fr;
  flex-grow: 1;
  height: 1px;
}

.form {
  padding: var(--standardMargin);
  padding-bottom: 0;
  border-bottom: 1px solid var(--borderColor);
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.item {
  box-sizing: border-box;
}

.item {
  border-right: 1px solid var(--borderColor);
  overflow-y: auto;
}

.item:last-child {
  border-right: 0;
}

.selectedHeader {
  font-size: 1.2em;
  font-weight: 600;
}

.paddedArea {
  padding: var(--smallMargin);
}

.dialog {
  min-width: min(2000px, 90vw);

  min-height: 90vh !important;
}

.body {
  padding: 0px;
  flex-grow: 1;
  height: 1px;
}
