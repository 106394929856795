.grid {
  display: grid;
  width: 100%;
  min-height: 575px;
  box-sizing: border-box;
  grid-template-columns: repeat(36, 0.25fr);
  grid-template-rows: repeat(5, 115px);
}

.techItem {
  overflow-wrap: break-word;
  width: 100%;
  display: flex;
  word-wrap: break-word;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 115px;
  max-height: 115px;
  text-align: center;
  font-size: 0.85em;
  opacity: 0.2;
  position: relative;
  box-sizing: border-box;
}

.techItem > div {
  height: 100%;
}

.techItem:hover {
  opacity: 0.6;
  cursor: pointer;
}

.techItem.enabled {
  opacity: 1;
}

.techItem {
  font-weight: 600;
  color: var(--surface1Color);
}
.techItem img {
  margin-bottom: var(--standardMargin);
  max-width: 40px;
  max-height: 40px;
}

.energyPipe {
  height: 4px;
  width: 100%;
}

.subtitle {
  font-size: 0.75em;
  color: var(--surface2Color);
  margin-top: var(--smallMargin);
}

.editButton {
  position: absolute;
  top: 0;
  right: 0px;
}

.editButton svg {
  font-size: 15px;
  color: var(--surface2Color);
}

.techTable {
  margin-top: var(--largeMargin);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: var(--largeMargin);
}

.energyStatus {
  margin-top: var(--largeMargin);
  margin-bottom: var(--largeMargin);
  flex-shrink: 0;
  font-size: 0.9em;
}

.techItemInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--surface4Color);
  background-color: var(--surface6Color);
  padding: var(--smallMargin);
  border-radius: 4px;
  min-height: 100px;
  min-width: 120px;
  box-sizing: border-box;
  position: relative;
}

@media (max-width: 1720px) {
  .techItem {
    font-size: 0.75em;
  }

  .techItemInner {
    min-width: 75px;
  }
}

.secosimBuildingArea {
  display: grid;
  width: 100%;
  min-height: 576px;
  box-sizing: border-box;
  grid-template-columns: repeat(150, 1fr);
  grid-template-rows: repeat(144, 4px);
}

@media (max-width: 1510px) {
  .techItem {
    font-size: 0.7em;
  }

  .techItem img {
    max-width: 30px;
    max-height: 30px;
  }

  .grid {
    min-height: 755px;
    grid-template-rows: repeat(5, 150px);
  }

  .techItem {
    height: 150px;
    max-height: 150px;
    word-break: break-all;
  }

  .secosimBuildingArea {
    min-height: 780px;
    grid-template-rows: repeat(144, 6px);
  }
}
